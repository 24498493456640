import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const software = {
  header: {
    title: 'softwareHeaderTitle',
    subTitle: 'softwareHeaderSubTitle',
    image: 'software.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'ZOO_get_started',
      id: 'sign_up_software'
    },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'ZOO_request_demo',
      id: 'request_demo_software'
    }
  },
  content: [
    {
      title: 'softwareContentZooTitle',
      subTitle: 'softwareContentZooSubTitle',
      image: 'zooNew.png',
    },
    {
      title: 'softwareContentSoftTitle',
      subTitle: 'softwareContentSoftSubTitle',
      image: 'grafNew.png',
    },
    {
      title: 'softwareContentDashTitle',
      subTitle: 'softwareContentDashSubTitle',
      image: 'instrumentsNew.png',
    },
    {
      title: 'softwareContentEscalTitle',
      subTitle: 'softwareContentEscalSubTitle',
      image: 'clockNew.png',
    },
    {
      title: 'softwareContentTimeTitle',
      subTitle: 'softwareContentTimeSubTitle',
      image: 'lightNew.png',
    }
  ],
  demo: {
    event: 'ZOO_digital_core_start'
  }
};

const Software = (props) => (
  <Layout {...props}>
    <Landing
      data={software}
      page={'software'}
    />
  </Layout>
);

export default withIntl(Software);

